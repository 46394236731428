
import { defineComponent, ref, toRefs } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import {
  barChartLabelsPlugin, barChartOptions, barChartRatioPlugin
} from '@/definitions/shared/charts/_general/bar/data'

Chart.register(...registerables)
Chart.defaults.font = {
  family: 'Inter',
}

export default defineComponent({
  components: {
    BarChart,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    showRatio: {
      type: Boolean,
    },
  },
  setup(props) {
    const { showRatio } = toRefs(props)
    const options = ref(barChartOptions)
    options.value.plugins.barRatio.showRatio = showRatio.value

    return {
      options,
      barChartRatioPlugin,
      barChartLabelsPlugin,
    }
  },
})
