
import { defineComponent, ref } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import { horizontalBarChartOptions, horizontalBarChartLabelsPlugin } from '@/definitions/shared/charts/_general/horizontalBar/data'

Chart.register(...registerables)
Chart.defaults.font = {
  family: 'Inter',
}

export default defineComponent({
  components: {
    BarChart,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '220px',
    },
  },
  setup() {
    const options = ref(horizontalBarChartOptions)

    return {
      horizontalBarChartLabelsPlugin,
      options,
    }
  },
})
