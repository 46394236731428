import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { formatNumber } from '@/services/utils'
import { useChartTooltip } from '@/compositions/chart/chartTooltip'
import type { ChartTooltipDataType } from '@/compositions/chart/chartTooltip'

const { initTooltip, removeTooltip, fillTooltip } = useChartTooltip()

const externalTooltipHandler = (context: Record<string, any>): undefined => {
  const { chart, tooltip } = context
  const tooltipClass = 'horizontal-bar-chart__tooltip'
  initTooltip(context, tooltipClass, true)

  if (tooltip.opacity === 0) {
    removeTooltip()
    return
  }

  if (tooltip.body) {
    const bodyLines = tooltip.dataPoints
    const tooltipData: ChartTooltipDataType[] = []

    const chartDatasets = chart.getDatasetMeta(0)._dataset

    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i]

      const currentBarData = chartDatasets.data[body.dataIndex]

      const title = body.label
      const color = colors.backgroundColor

      const dataText = currentBarData.tooltipValueLabel || 'Value'
      const dataValue = `${currentBarData.value}`

      tooltipData.push({
        text: dataText,
        value: dataValue,
        color: color,
      })

      fillTooltip(tooltipClass, title, tooltipData)
    })
  }
}

export const horizontalBarChartOptions: Record<string, any> = {
  parsing: {
    yAxisKey: 'label',
    xAxisKey: 'value',
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
    },
  },
  indexAxis: 'y',
  borderRadius: 6,
  barThickness: 26,
  responsive: true,
  scales: {
    yAxis: {
      // Ticks smart truncate
      afterTickToLabelConversion: (axis: any) => {
        const minWidth = 100 // Minimum width for tick labels
        const maxWidth = axis.chart.width * 0.2 >= minWidth ? axis.chart.width * 0.2 : minWidth // Maximum width for tick labels, 20% of chart or minWidth
        const fontSize = 14
        const ctx = axis.ctx

        ctx.font = `${fontSize}px 'Inter'`

        axis.ticks = axis.ticks.map((e: any) => {
          const textWidth = ctx.measureText(e.label).width
          const isTextEllipsis = textWidth > maxWidth
          let label = e.label

          // If the text width exceeds the max width, truncate the text
          if (isTextEllipsis) {
            while (ctx.measureText(label + '...').width > maxWidth) {
              label = label.slice(0, -1)
            }
          }

          return {
            ...e,
            label: isTextEllipsis ? label + '...' : label,
          }
        })
      },
      grid: {
        display: false,
        borderColor: rgbToHex(styleVars.subtle),
        offset: false,
      },
      ticks: {
        color: rgbToHex(styleVars.neutral),
        padding: 4,
        font: {
          size: '14px',
          style: 'normal',
          weight: '400',
        },
      },
    },
    xAxis: {
      afterDataLimits: (axis: any) => {
        axis.max = axis.max * 1.1
        axis.end = axis.max
      },
      grid: {
        color: rgbToHex(styleVars.subtle),
        drawBorder: false,
        borderDash: [7, 3],
      },
      ticks: {
        color: '#475467',
        beginAtZero: true,
        padding: 8,
        font: {
          size: '12px',
          style: 'normal',
          weight: '400',
        },
        callback: (val: number) => {
          return val >= 1000 ? `${(val / 1000).toFixed(1)}К` : `${val}`
        },
      },
    },
  },
}

export const horizontalBarChartLabelsPlugin = {
  id: 'horizontalBarLabels',
  beforeDatasetsDraw: (chart: Record<string, any>): void => {
    const { ctx } = chart
    const chartDatasets = chart.getDatasetMeta(0)

    chartDatasets.data.forEach((bar: any, i: number) => {
      const textY = bar.y
      const textX = bar.x

      ctx.textAlign = 'left'
      ctx.fillStyle = rgbToHex(styleVars.emphasize)
      ctx.font = '500 12px Inter'

      ctx.fillText(formatNumber(chartDatasets._dataset.data[i].value), textX + 8, textY + 4)
    })
  },
}
