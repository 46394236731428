import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { useChartTooltip } from '@/compositions/chart/chartTooltip'
import type { ChartTooltipDataType } from '@/compositions/chart/chartTooltip'

const { initTooltip, removeTooltip, fillTooltip } = useChartTooltip()

const externalTooltipHandler = (context: Record<string, any>): undefined => {
  const { chart, tooltip } = context
  const tooltipClass = 'bar-chart__tooltip'
  initTooltip(context, tooltipClass)

  if (tooltip.opacity === 0) {
    removeTooltip()
    return
  }

  if (tooltip.body) {
    const bodyLines = tooltip.dataPoints
    const tooltipData: ChartTooltipDataType[] = []

    const chartDatasets = chart.getDatasetMeta(0)._dataset

    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i]

      const currentBarData = chartDatasets.data[body.dataIndex]
      const nextBarData = chartDatasets.data[body.dataIndex + 1]

      const title = body.label
      const color = colors.backgroundColor

      const dataText = currentBarData.tooltipValueLabel || 'Value'
      const dataValue = `${currentBarData.value} ${currentBarData.tooltipValuePostfix}s`

      tooltipData.push({
        text: dataText,
        value: dataValue,
        color: color,
      })

      if (nextBarData) {
        const dataPercentsText = currentBarData.tooltipRatioLabel || 'Conversion'
        const dataPercentsValue = `${Math.round(nextBarData.value / currentBarData.value * 100)}%`

        tooltipData.push({
          text: dataPercentsText,
          value: dataPercentsValue,
          valuePostfix: `(${nextBarData.value} deals)`,
          color: color,
        })
      }

      fillTooltip(tooltipClass, title, tooltipData)
    })
  }
}

export const barChartOptions: Record<string, any> = {
  parsing: {
    yAxisKey: 'value',
    xAxisKey: 'label',
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
    },
    barRatio: {
      showRatio: false,
    },
  },
  borderRadius: 6,
  barThickness: 40,
  responsive: true,
  layout: {
    padding: {
      left: -20,
    },
  },
  scales: {
    xAxis: {
      grid: {
        display: false,
        borderColor: rgbToHex(styleVars.subtle),
      },
      ticks: {
        color: rgbToHex(styleVars.distinct),
        padding: 0,
        font: {
          size: '12px',
          style: 'normal',
          weight: '500',
        },
      },
    },
    yAxis: {
      grid: {
        color: rgbToHex(styleVars.subtle),
        drawBorder: false,
        borderDash: [7, 3],
      },
      afterUpdate: (axis: any) => {
        axis.paddingTop = 8
      },
      ticks: {
        color: rgbToHex(styleVars.distinct),
        beginAtZero: true,
        padding: 20,
        font: {
          size: '12px',
          style: 'normal',
          weight: '500',
        },
      },
    },
  },
}

export const barChartLabelsPlugin = {
  id: 'barLabels',
  afterDatasetsDraw: (chart: Record<string, any>): void => {
    const { ctx } = chart
    const chartDatasets = chart.getDatasetMeta(0)

    chartDatasets.data.forEach((dataset: any, i: number) => {
      const textY = dataset.y
      const textX = dataset.x

      ctx.textAlign = 'center'
      ctx.fillStyle = rgbToHex(styleVars.emphasize)
      ctx.font = '600 12px Inter'

      ctx.fillText(chartDatasets._dataset.data[i].value, textX, textY - 8)
    })
  },
}

export const barChartRatioPlugin = {
  id: 'barRatio',
  afterDatasetsDraw: (chart: Record<string, any>, args: Record<string, any>, options: Record<string, any>): void => {
    if (!options.showRatio) { return }

    const { ctx } = chart
    const chartDatasets = chart.getDatasetMeta(0)

    chartDatasets.data.forEach((dataset: any, i: number) => {
      if (i !== chartDatasets.data.length - 1) {
        const chartRootComponent = ctx.canvas.parentNode.parentNode
        let ratioLabel = chartRootComponent?.querySelector(`#bar-chart-ratio-${i}`)

        if (!ratioLabel) {
          ratioLabel = document.createElement('div')
          ratioLabel.setAttribute('id', `bar-chart-ratio-${i}`)
          ratioLabel.classList.add('bar-chart__ratio')

          chartRootComponent?.appendChild(ratioLabel)
        }

        const ratioLabelX = ((dataset.x + dataset.width / 2) + (chartDatasets.data[i + 1].x - chartDatasets.data[i + 1].width / 2)) / 2 - 3 // Find x position between two bars
        ratioLabel.style.left = `${ratioLabelX}px`

        const currentBarData = chartDatasets._dataset.data[i].value
        const nextBarData = chartDatasets._dataset.data[i + 1].value

        ratioLabel.textContent = `${Math.round(nextBarData / currentBarData * 100)}%`
      }
    })
  },
}
