
import { defineComponent } from 'vue'
import LineChart from '@/components/shared/charts/LineChart.vue'
import { getEmailCampaignsTimelineChartData } from '@/definitions/shared/charts/campaigns/data'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'
import { reportingDealsConversationData, reportingDealsPipelineData, reportingOverviewDeliveryChartData } from '@/definitions/shared/charts/reporting/data'
import HorizontalBarChart from '@/components/shared/charts/HorizontalBarChart.vue'

export default defineComponent({
  components: {
    HorizontalBarChart,
    BarChart,
    DoughnutChart,
    LineChart,
  },
  setup() {
    return {
      getEmailCampaignsTimelineChartData,
      reportingDealsConversationData,
      reportingDealsPipelineData,
      reportingOverviewDeliveryChartData,
    }
  },
})
