import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { getColorObject } from '@/services/charts/chart'

const emptyChart = (chartData: any) => {
  return {
    ...chartData,
    datasets: chartData.datasets.map((dataset: any) => ({
      ...dataset,
      data: dataset.data.map(() => 0),
    })),
  }
}

export const reportsDealsTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      fill: true,
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      fill: true,
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportsDealsTimelineChartDataHalfEmpty = emptyChart(reportsDealsTimelineChartData)

export const reportsHelpdeskTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      fill: true,
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      fill: true,
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportsHelpdeskTimelineChartDataHalfEmpty = emptyChart(reportsHelpdeskTimelineChartData)

export const reportsHelpdeskPriorityChartData = {
  labels: [
    'Low',
    'Normal',
    'High',
    'Urgent',
  ],
  datasets: [{
    data: [
      {
        value: 7210,
        tooltipValueLabel: 'Low',
        tooltipPercentLabel: '% of all tickets',
        priority: '100',
      },
      {
        value: 5690,
        tooltipValueLabel: 'Normal',
        tooltipPercentLabel: '% of all tickets',
        priority: '200',
      },
      {
        value: 3230,
        tooltipValueLabel: 'High',
        tooltipPercentLabel: '% of all tickets',
        priority: '300',
      },
      {
        value: 2200,
        tooltipValueLabel: 'Urgent',
        tooltipPercentLabel: '% of all tickets',
        priority: '400',
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
  }],
}

export const reportsTasksTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      fill: true,
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      fill: true,
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportsTasksTimelineChartDataHalfEmpty = emptyChart(reportsTasksTimelineChartData)

export const reportsTasksPriorityChartData = {
  labels: [
    'Low',
    'Normal',
    'High',
    'Urgent',
  ],
  datasets: [{
    data: [
      {
        value: 7210,
        tooltipValueLabel: 'Low',
        tooltipPercentLabel: '% of all tickets',
        priority: '100',
      },
      {
        value: 5690,
        tooltipValueLabel: 'Normal',
        tooltipPercentLabel: '% of all tickets',
        priority: '200',
      },
      {
        value: 3230,
        tooltipValueLabel: 'High',
        tooltipPercentLabel: '% of all tickets',
        priority: '300',
      },
      {
        value: 2200,
        tooltipValueLabel: 'Urgent',
        tooltipPercentLabel: '% of all tickets',
        priority: '400',
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
  }],
}

export const reportsTasksSourceChartData = {
  labels: [
    'Messenger',
    'Deals',
    'Automations',
    'Tickets',
    'Tasks',
    'API',
  ],
  datasets: [{
    data: [
      {
        value: 7210,
        tooltipValueLabel: 'Messenger',
        tooltipPercentLabel: '% of all tasks',
      },
      {
        value: 2115,
        tooltipValueLabel: 'Deals',
        tooltipPercentLabel: '% of all tasks',
      },
      {
        value: 1934,
        tooltipValueLabel: 'Automations',
        tooltipPercentLabel: '% of all tasks',
      },
      {
        value: 1548,
        tooltipValueLabel: 'Tickets',
        tooltipPercentLabel: '% of all tasks',
      },
      {
        value: 956,
        tooltipValueLabel: 'Tasks',
        tooltipPercentLabel: '% of all tasks',
      },
      {
        value: 1421,
        tooltipValueLabel: 'API',
        tooltipPercentLabel: '% of all tasks',
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.mint500),
      rgbToHex(styleVars.purple500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.mint500),
      rgbToHex(styleVars.purple500),
    ],
  }],
}

export const reportingOverviewTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [174, 240, 333, 150, 278, 301, 361, 210],
      fill: true,
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Previous period',
      data: [240, 270, 384, 190, 378, 401, 421, 290],
      fill: true,
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportingOverviewTimelineChartDataHalfEmpty = emptyChart(reportingOverviewTimelineChartData)

export const reportingOverviewDeliveryChartData = {
  labels: [
    'Sent',
    'Delivered',
    'Rejected',
    'Failed',
  ],
  datasets: [{
    data: [
      {
        value: 6210,
        tooltipValueLabel: 'Sent',
        tooltipPercentLabel: '% of all deliveries',
      },
      {
        value: 690,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
      },
      {
        value: 430,
        tooltipValueLabel: 'Rejected',
        tooltipPercentLabel: '% of all deliveries',
      },
      {
        value: 320,
        tooltipValueLabel: 'Failed',
        tooltipPercentLabel: '% of all deliveries',
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.red500),
    ],
  }],
}

export const reportingOverviewGeographyChartData = {
  labels: [
    'United States',
    'Thailand',
    'France',
    'Spain',
    'Other countries',
  ],
  datasets: [{
    data: [
      {
        value: 7210,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'us',
          name: 'United States',
        },
      },
      {
        value: 5510,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'th',
          name: 'Thailand',
        },
      },
      {
        value: 3210,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'fr',
          name: 'France',
        },
      },
      {
        value: 2105,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'es',
          name: 'Spain',
        },
      },
      {
        value: 510,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          name: 'Other countries',
        },
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.mint500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.purple500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.mint500),
      rgbToHex(styleVars.blue500),
      rgbToHex(styleVars.purple500),
    ],
  }],
}

export const reportingOverviewCallsTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      ...getColorObject(styleVars.purple500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportingOverviewCallsTimelineChartDataHalfEmpty = emptyChart(reportingOverviewCallsTimelineChartData)

export const reportingOverviewLiveChatsTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      ...getColorObject(styleVars.mint500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportingOverviewLiveChatsTimelineChartDataHalfEmpty = emptyChart(reportingOverviewLiveChatsTimelineChartData)

export const reportingOverviewSocialChannelsTimelineChartData = {
  labels: ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug'],
  datasets: [
    {
      label: 'Current period',
      data: [25, 20, 90, 70, 75, 25, 45, 50],
      ...getColorObject(styleVars.crimson500),
    },
    {
      label: 'Previous period',
      data: [30, 31, 32, 20, 20, 25, 32, 40],
      ...getColorObject(styleVars.orange500),
    },
  ],
}
export const reportingOverviewSocialChannelsTimelineChartDataHalfEmpty = emptyChart(reportingOverviewSocialChannelsTimelineChartData)

export const reportingDealsConversationData = {
  datasets: [{
    data: [
      {
        label: 'First email',
        value: 23,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
      {
        label: 'Contact made',
        value: 19,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
      {
        label: 'Needs defined',
        value: 9,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
      {
        label: 'Proposal made',
        value: 7,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
      {
        label: 'Negotiation',
        value: 5,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
      {
        label: 'Won',
        value: 3,
        tooltipValueLabel: 'Reached stage',
        tooltipRatioLabel: 'Conversion to next stage',
        tooltipValuePostfix: 'deal',
      },
    ],
    backgroundColor: [rgbToHex(styleVars.purple200), rgbToHex(styleVars.purple300), rgbToHex(styleVars.purple400), rgbToHex(styleVars.purple500), rgbToHex(styleVars.purple600), rgbToHex(styleVars.green500)],
  }],
}

export const reportingDealsPipelineData = {
  datasets: [
    {
      data: [
        {
          label: '💰Sales U💰Sales U💰Sales U💰Sales U💰Sales U💰Sales US',
          value: 1480,
          tooltipValueLabel: 'Total deals number',
        },
        {
          label: '🇬🇧Sales UK',
          value: 928,
          tooltipValueLabel: 'Total deals number',
        },
        {
          label: '🇺🇸Real estate US',
          value: 834,
          tooltipValueLabel: 'Total deals number',
        },
        {
          label: '🇬🇧Real estate UK',
          value: 484,
          tooltipValueLabel: 'Total deals number',
        },
        {
          label: 'Others',
          value: 540,
          tooltipValueLabel: 'Total deals number',
        },
      ],
      backgroundColor: [rgbToHex(styleVars.purple500), rgbToHex(styleVars.orange500), rgbToHex(styleVars.green500), rgbToHex(styleVars.mint500), rgbToHex(styleVars.blue500)],
    },
  ],
}

export const reportingTagsData = {
  datasets: [
    {
      data: [
        {
          label: 'integration',
          value: 1480,
          tooltipValueLabel: 'Total tags number',
        },
        {
          label: 'refund',
          value: 928,
          tooltipValueLabel: 'Total tags number',
        },
        {
          label: 'auth',
          value: 834,
          tooltipValueLabel: 'Total tags number',
        },
        {
          label: 'payment',
          value: 484,
          tooltipValueLabel: 'Total tags number',
        },
        {
          label: 'toll-free form v...',
          value: 540,
          tooltipValueLabel: 'Total tags number',
        },
      ],
      backgroundColor: [rgbToHex(styleVars.purple500), rgbToHex(styleVars.orange500), rgbToHex(styleVars.green500), rgbToHex(styleVars.mint500), rgbToHex(styleVars.blue500)],
    },
  ],
}
