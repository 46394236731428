import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "horizontal-bar-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bar_chart = _resolveComponent("bar-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bar_chart, {
      options: _ctx.options,
      "chart-data": _ctx.chartData,
      plugins: [_ctx.horizontalBarChartLabelsPlugin],
      style: _normalizeStyle({
        'height': _ctx.height,
      })
    }, null, 8, ["options", "chart-data", "plugins", "style"])
  ]))
}